<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Basic Details</h3>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-simple-table>
      <tbody>
        <tr>
          <th>Full Name</th>
          <td>{{ teamMember.user.full_name }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{{ teamMember.user.email }}</td>
        </tr>
        <tr>
          <th>Last Logged In</th>
          <td></td>
        </tr>
        <tr>
          <th>Created At</th>
          <td>{{ teamMember.formatted_dates.created_at }}</td>
        </tr>
        <tr>
          <th>User Type</th>
          <td>{{ teamMember.team_member_type.description }}</td>
        </tr>
        <tr>
          <th>Roles</th>
          <td>
            <v-chip
              small
              v-for="role in teamMember.user.roles"
              v-bind:key="role.id"
              >{{ role.name }}</v-chip
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },
};
</script>
